import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
  Paper,
  Chip,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import QueueSelect from "../QueueSelect";
import DaySelect from "../DaySelect";
import TimeSelect from "../TimeSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      paddingRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    minWidth: 120,
  },
  chip: {
    margin: 2,
    width: "100%",
  },
  dateTimeConfig: {
    "& > *": {
      marginTop: theme.spacing(1),
    },
  },
  scheduleModalTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ScheduleSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  queueOptionId: Yup.string().required("Obrigatório"),
  startAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({ open, onClose, scheduleId, reload }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    name: "",
    queueOptionId: "",
    sentAt: "",
    startAt: "",
    stopt: "",
    repeatEveryDays: 0,
  };

  const initialWeekDays = {
    seg: false,
    ter: false,
    qua: false,
    qui: false,
    sex: false,
    sab: false,
    dom: false,
  };

  const [schedule, setSchedule] = useState(initialState);
  const [queueOptions, setQueueOptions] = useState([]);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [tab, setTab] = useState(0);
  const [selectedMonthDays, setSelectedMouthDays] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [weekDays, setWeekDays] = useState(initialWeekDays);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      try {
        (async () => {
          const queueOptions = await api.get(`/queue-options`, {
            params: { companyId },
          });
          setQueueOptions(queueOptions.data);

          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);
          setSchedule((prevState) => {
            return {
              ...prevState,
              ...data,
              startAt: moment(data.startAt).format("YYYY-MM-DDTHH:mm"),
              stopAt: data.stopAt
                ? moment(data.stopAt).format("YYYY-MM-DDTHH:mm")
                : "",
            };
          });
          setSelectedQueueIds(data.queues.map((queue) => queue.id));
          const dataWeekDays = data.days.filter(
            (record) => record.dayBy == "week"
          );
          Object.keys(weekDays).forEach(
            (key) =>
              (weekDays[key] = dataWeekDays
                .map((record) => record.day)
                .includes(key))
          );
          setSelectedTimes(data.times.map((record) => moment(record.time)));
          setSelectedMouthDays(
            data.days
              .filter((record) => record.dayBy == "month")
              .map((record) => record.day)
          );
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [scheduleId, open, user]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      try {
        (async () => {
          const queueOptions = await api.get(`/queue-options`, {
            params: { companyId, queueIds: selectedQueueIds },
          });
          setQueueOptions(queueOptions.data);
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [selectedQueueIds]);

  const handleClose = () => {
    onClose();
    setSchedule(initialState);
    setSelectedQueueIds([]);
    setSelectedMouthDays([]);
    setWeekDays(initialWeekDays);
    setSelectedTimes([]);
  };

  const handleSaveSchedule = async (values) => {
    let selectedWeekDays = [];
    Object.keys(weekDays).forEach((key) => {
      if (weekDays[key]) selectedWeekDays.push(key);
    });
    const scheduleData = {
      ...values,
      startAt: moment(values.startAt),
      stopAt: values.stopAt ? moment(values.stopAt) : "",
      userId: user.id,
      queues: selectedQueueIds,
      times: selectedTimes,
      weekDays: selectedWeekDays,
      monthDays: selectedMonthDays,
    };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
      } else {
        await api.post("/schedules", scheduleData);
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload("UPDATE_SCHEDULES");
      }
    } catch (err) {
      toastError(err);
    }
    setSchedule(initialState);
    handleClose();
  };

  const DatetimeConfig = () => {
    return (
      <>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setTab(v)}
          style={{ marginBottom: "16px" }}
        >
          <Tab label="Rotina" />
          <Tab label="Dias específicos do mês" />
        </Tabs>
        {tab === 0 && (
          <div className={classes.dateTimeConfig}>
            <Field
              as={TextField}
              type="number"
              label={i18n.t("scheduleModal.form.repeatEveryDays")}
              name="repeatEveryDays"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <div style={{ display: "flex" }}>
              {Object.keys(weekDays).map((day) => (
                <Chip
                  key={day}
                  style={{ backgroundColor: weekDays[day] ? "grey" : "white" }}
                  onClick={() =>
                    setWeekDays({ ...weekDays, [day]: !weekDays[day] })
                  }
                  variant="outlined"
                  label={day.toLocaleUpperCase()}
                  className={classes.chip}
                />
              ))}
            </div>
            <TimeSelect
              setTimesList={setSelectedTimes}
              timesList={selectedTimes}
            />
          </div>
        )}
        {tab === 1 && (
          <div className={classes.dateTimeConfig}>
            <DaySelect
              setDaysList={setSelectedMouthDays}
              daysList={selectedMonthDays}
            />
            <TimeSelect
              setTimesList={setSelectedTimes}
              timesList={selectedTimes}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <div className={classes.scheduleModalTitle}>
            <span>
              {schedule.status === "ERRO"
                ? "Erro de Envio"
                : `Mensagem ${capitalize(schedule.status)}`}
            </span>
            <span>{schedule.lastSentAt ? `${i18n.t("scheduleModal.title.lastSent")}: ${moment(new Date(schedule.lastSentAt)).format("DD/MM/yy HH:mm")}`: ""}</span>
          </div>
        </DialogTitle>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("scheduleModal.form.name")}
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(values) => setSelectedQueueIds(values)}
                />
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="queueOption-selection-label">
                    {i18n.t("scheduleModal.form.queueOption")}
                  </InputLabel>
                  <Field
                    as={Select}
                    label={i18n.t("scheduleModal.form.queueOption")}
                    placeholder={i18n.t("scheduleModal.form.queueOption")}
                    labelId="queueOption-selection-label"
                    id="queueOptionId"
                    name="queueOptionId"
                    error={
                      touched.queueOptionId && Boolean(errors.queueOptionId)
                    }
                  >
                    {queueOptions &&
                      queueOptions.map((queueOption) => (
                        <MenuItem key={queueOption.id} value={queueOption.id}>
                          {queueOption.title}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("scheduleModal.form.startAt")}
                    type="datetime-local"
                    name="startAt"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.startAt && Boolean(errors.startAt)}
                    helperText={touched.startAt && errors.startAt}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("scheduleModal.form.stopAt")}
                    type="datetime-local"
                    name="stopAt"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <DatetimeConfig />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {schedule.sentAt === null || schedule.sentAt === ""
                    ? i18n.t("scheduleModal.buttons.cancel")
                    : i18n.t("scheduleModal.buttons.back")}
                </Button>
                {(schedule.sentAt === null || schedule.sentAt === "") && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {scheduleId
                      ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                      : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
