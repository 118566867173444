import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputMask from 'react-input-mask';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "admin",
		allTicket: "desabled"
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [whatsappId, setWhatsappId] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [roles, setRoles] = useState([]);
	const [selectedRoleIds, setSelectedRoleIds] = useState([]);
	const { loading, whatsApps } = useWhatsApps();

	useEffect(() => {
		const fetchUser = async () => {
			const roles = await api.get(`/roles/all`);
			setRoles(roles.data);

			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
				setSelectedRoleIds(data.roles?.map(queue => queue.id));
				setPhoneNumber(data.phoneNumber)
			} catch (err) {
				toastError(err);
			}
		};

		if (userId)
			fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, whatsappId, queueIds: selectedQueueIds, allTicket: values.allTicket, roles: selectedRoleIds, phoneNumber: phoneNumber };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.password")}
										type="password"
										name="password"
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
								<InputMask
									id="phoneNumber"
									name="phoneNumber"
									mask="+99 (99) 99999-9999"
									error={touched.phoneNumber && Boolean(errors.phoneNumber)}
									helperText={touched.phoneNumber && errors.phoneNumber}
									autoComplete="phoneNumber"
									minLength={14}
									onChange={(e) => setPhoneNumber(e.target.value)}
									value={phoneNumber}
									required
								>
									{({ field }) => (
										<TextField
											{...field}
											variant="outlined"
											fullWidth
											label={i18n.t("userModal.form.phoneNumber")}
											margin="dense"
										/>
									)}
								</InputMask>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									{/* <FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<InputLabel id="profile-selection-input-label">
											{i18n.t("userModal.form.profile")}
										</InputLabel>
										<Field
											as={Select}
											label={i18n.t("userModal.form.profile")}
											name="profile"
											labelId="profile-selection-label"
											id="profile-selection"
											required
										>
											<MenuItem value="admin">Admin</MenuItem>
											<MenuItem value="user">User</MenuItem>
										</Field>	
									</FormControl> */}
								</div>
								<FormControl
									variant="outlined"
									margin="dense"
									fullWidth
								>
									<InputLabel id="role-selection-input-label">
										{i18n.t("userModal.form.role")}
									</InputLabel>
									<Field
										as={Select}
										label={i18n.t("userModal.form.role")}
										name="roles"
										labelId="role-selection-label"
										id="role-selection"
										value={selectedRoleIds}
										onChange={values => setSelectedRoleIds(values.target.value)}
										multiple
										required
										fullWidth
									>
										{roles?.map((role) => (
											<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
										))}
									</Field>
								</FormControl>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={values => setSelectedQueueIds(values)}
								/>

								<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
									<InputLabel>
										{i18n.t("userModal.form.whatsapp")}
									</InputLabel>
									<Field
										as={Select}
										value={whatsappId}
										onChange={(e) => setWhatsappId(e.target.value)}
										label={i18n.t("userModal.form.whatsapp")}

									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{whatsApps.map((whatsapp) => (
											<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
										))}
									</Field>
								</FormControl>
								
								<div className={classes.divider}>
									<span className={classes.dividerText}>Liberações</span>
								</div>
								<div className={classes.textField}>
									<FormControl
										variant="outlined"
										className={classes.maxWidth}
										margin="dense"
										fullWidth
									>
										<>
											<InputLabel id="profile-selection-input-label">
												{i18n.t("userModal.form.allTicket")}
											</InputLabel>

											<Field
												as={Select}
												label={i18n.t("allTicket.form.viewTags")}
												name="allTicket"
												labelId="allTicket-selection-label"
												id="allTicket-selection"
												required
											>
												<MenuItem value="enabled">{i18n.t("userModal.form.allTicketEnabled")}</MenuItem>
												<MenuItem value="desabled">{i18n.t("userModal.form.allTicketDesabled")}</MenuItem>
											</Field>
										</>
									</FormControl>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
