import axios from "axios";

let baseBackendUrl = process.env.REACT_APP_BACKEND_URL;
if (process.env.NODE_ENV == "production") {
	baseBackendUrl = 'https://jmfapi.codefi.com.br';
}

const api = axios.create({
	baseURL: baseBackendUrl,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: baseBackendUrl
});

export default api;
